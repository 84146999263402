import { routeToUrl } from "@app-routes";
import JumbotronImage from "@public/images/illustrations/jumbotron.svg";
import JumbotronStamp from "@public/images/illustrations/stamp.svg";
import { Button } from "@uxf/ui/button";
import Link from "next/link";
import React from "react";
import styles from "./jumbotron.module.css";

export function Jumbotron() {
    return (
        <div className="relative bg-surface-background-primary md:flex">
            <div className="relative max-w-[1088px] md:container max-md:flex max-md:flex-col max-md:items-center md:h-[580px]">
                <div className="z-10 w-full max-md:shadow">
                    <div className="pb-14">
                        <h1 className="uxf-typo-h2 px-5 pb-8 pt-10 text-secondary max-md:text-center md:px-0 md:pb-6 md:pt-20">
                            Interaktivní vzdělávání <br />
                            <span className="text-textLow">studentů v&nbsp;energetice</span>
                        </h1>
                        <p className="uxf-typo-h4 px-5 pb-12 font-normal max-md:text-center md:w-[590px] md:px-0 md:pb-10">
                            Výukový program pro sedmáky, osmáky a&nbsp;deváťáky
                        </p>
                        <div className="text-buttonMedium max-md:text-center">
                            <Link href={routeToUrl("auth/register")} passHref legacyBehavior>
                                <Button size="xl" className="px-10 py-3">
                                    Registrovat školu
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex max-w-[396px] justify-center max-md:relative">
                    <JumbotronImage className="z-0 mx-0 w-[300px] xs:w-[340px] md:absolute md:right-12 md:top-0 md:w-[396px] md:pt-0" />
                    <JumbotronStamp className="absolute -bottom-2 -right-2 z-20 size-[150px] md:bottom-0 md:right-10 md:size-[180px] xl:-bottom-16 xl:right-0 xl:size-[242px]" />
                </div>
            </div>
            <div className={styles.wave} />
        </div>
    );
}
